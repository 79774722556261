import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import clsx from 'clsx';
//styles
import styles from './mvp.module.css';
//components
import Bullets from 'components/bullets';
import Booking from 'components/feedback';
import Layout from '../components/layout';
import Recognition from '../../recognition';
import Blockquote from '../components/blockquote';
import {
  Gokada,
  EveryPig,
  LadderOut,
  Adact,
  Heypractice,
  RevenueBoosters,
} from 'components/carousel';
import Technology, { TechnologyGroup } from '../../technology';
import Tabs from '../../tabs';
import loadable from '@loadable/component';
import FAQ from 'components/faq';
// hooks
import { useFaqData } from 'hooks/useFaqData';
//data
import mvp from '../components/mvp.json';
import { meta } from '../../../metaData';

const LIST = [
  {
    title: 'INTRO call',
  },
  {
    title: 'Discovery phase',
  },
  {
    title: 'Design phase',
  },
  {
    title: 'Development phase',
  },
  {
    title: 'Product launch',
  },
  {
    title: 'support',
  },
];

interface Props {
  location: Location;
}

function Main({ location }: Props) {
  const faqData = useFaqData('allStrapiService', 'MVP development');
  const data = useStaticQuery(graphql`
    query {
      davidNeuendorf: file(relativePath: { eq: "david-neuendorf.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      heyPractice: file(relativePath: { eq: "hey-practice.svg" }) {
        publicURL
      }
      kalev: file(relativePath: { eq: "kalev-adact.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      adact: file(relativePath: { eq: "img/adact-black-logo.svg" }) {
        publicURL
      }
      patrickKedziora: file(relativePath: { eq: "kedziora.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      kedzoh: file(relativePath: { eq: "kedzoh-logo.svg" }) {
        publicURL
      }
    }
  `);
  const SwiperSlider = loadable(() => import('components/swiper-slider'));
  const ReviewSlider = loadable(() => import('components/swiper-slider/review-slider'));

  return (
    <Layout
      title="MVP Development"
      subtitle="Reduce time-to-market with our fast &amp; agile development process. The result is a functional version of your product with all core features ready to test with users."
      metaData={{ main: meta.mvpDevelopment, faq: faqData?.list }}
      pathname={location.pathname}
    >
      <section className={clsx('section', styles.mvp)}>
        <div className="inner">
          <h2 className={clsx(styles.bulletsTitle, styles.title)}>Why opt for an MVP?</h2>
          <p className="subtitle">Save your time. Save your money. Stay out of the competition.</p>
          <Bullets list={mvp} className={styles.bullets} />
        </div>
      </section>
      <section className={styles.carousel}>
        <SwiperSlider>
          <RevenueBoosters />
          <Adact />
          <Heypractice />
          <Gokada />
          <EveryPig />
          <LadderOut />
        </SwiperSlider>
      </section>
      <section className={clsx(styles.section, styles.workProcessSection)}>
        <div className="inner">
          <h2 className="title">Work process</h2>
          <Tabs list={LIST} btnClassName="tabs__btn_30" isArrow>
            <li className="tabs__item">
              <article className="tabs__column_first">
                <p>
                  Here at Brocoders, we work shoulder-to-shoulder with you in an open manner
                  throughout the entire product life-cycle.
                </p>
                <p>
                  To cover all details and ensure the completeness of the requirements elicitation,
                  we start from a first call and meetings with a customer.
                </p>
              </article>
              <div className="tabs__column_last">
                <ul>
                  <li>
                    <h4>Timeframe</h4>
                    <p>1 hour</p>
                  </li>
                  <li>
                    <h4>Deliverables</h4>
                    <p>
                      NDA <br />
                      Consultation
                    </p>
                  </li>
                  <li>
                    <h4>Who involved</h4>
                    <p>
                      Account Manager <br />
                      Stakeholder <br />
                      Technical consultant
                    </p>
                  </li>
                  <li>
                    <h4>Apps</h4>
                    <TechnologyGroup technology={['skype', 'zoom', 'googleMeet']} />
                  </li>
                </ul>
              </div>
            </li>
            <li className="tabs__item">
              <article className="tabs__column_first">
                <p>
                  We apply the best relevant market research methodologies, study the business
                  processes and analyze competitors along with potential risks to end up with a
                  robust business strategy.
                </p>
                <p>
                  Either you are a startup that requires a new product, or a business that needs a
                  new feature to be integrated into an existing solution, we are ready to evaluate
                  your idea and provide a piece of expert advice.
                </p>
              </article>
              <div className="tabs__column_last">
                <ul>
                  <li>
                    <h4>Timeframe</h4>
                    <p>Up to 3 days</p>
                  </li>
                  <li>
                    <h4>Deliverables</h4>
                    <p>
                      {' '}
                      Project Proposal <br />
                      Contract for Prototype service
                    </p>
                  </li>
                  <li>
                    <h4>Who involved</h4>
                    <p>
                      {' '}
                      Account manager <br />
                      Business analyst <br />
                      Stakeholders
                    </p>
                  </li>
                </ul>
              </div>
            </li>
            <li className="tabs__item">
              <article className="tabs__column_first">
                <h3>Low fidelity prototype</h3>
                <p>
                  Based on gathered data and driven by the iterative development model, we identify
                  the feature flow and prepare the wireframes along with a low-fidelity MVP
                  prototype.
                </p>
                <h3>Feature-based estimation</h3>
                <p>
                  It’s crucial to evaluate the entire project and provide a customer with a clear
                  vision of how much time and money the development process might take. That said,
                  we use a feature decomposition to split the functionality into small parts, which
                  can then be analyzed by our technical experts.
                </p>
                <p>
                  Our customers have full access to all relevant data, so they can assess further
                  steps, as well as protect the budget to investors. Once it’s done, we adjust a
                  toolset and a tech stack for the development stage.
                </p>
                <h3>Design</h3>
                <p>
                  Our UI/UX designers have extensive experience in creating eye-catching and
                  user-friendly apps that proved to be remarkable solutions. We stick to Figma to
                  design the UI/UX elements for our MVP projects. If requested, we provide a
                  customer with an interactive prototype, so he can test the interface usability and
                  leave his feedback.
                </p>
              </article>
              <div className="tabs__column_last">
                <ul>
                  <li>
                    <h4>Timeframe</h4>
                    <p>1 month</p>
                  </li>
                  <li>
                    <h4>Deliverables</h4>
                    <p>
                      Low-fidelity prototype in Figma <br />
                      Feature-based estimation <br />
                      Feature-decomposition <br />
                      Graphic Project in Figma <br />
                      Contract for development
                    </p>
                  </li>
                  <li>
                    <h4>Who involved</h4>
                    <p>
                      Business Analyst <br />
                      Designer <br />
                      Project Manager <br />
                      Stakeholders <br />
                      Technical consultant
                    </p>
                  </li>
                  <li>
                    <h4>Apps</h4>
                    <Technology list={['figma', 'notion']} />
                  </li>
                </ul>
              </div>
            </li>
            <li className="tabs__item">
              <article className="tabs__column_first">
                <p>
                  It’s time to establish a development environment, define the project’s
                  architecture, make a roadmap and choose the right candidates to set up a team that
                  fits your company’s requirements and vision. To strengthen the overall performance
                  and ensure everything is working properly, we carry out functional, integration
                  and usability tests. We also integrate manual and auto QA testing at this stage.
                </p>
                <p>
                  At the end of each sprint, we provide the stakeholders with a demo to showcase the
                  workflow and the functionality we’ve already made. Hence, all the stakeholders
                  have a clear vision of the working process, so they can leave feedback and make
                  sure we are on the right path.
                </p>
                <h3>Project management</h3>
                <p>
                  We involve experienced project managers to take care of every step and the entire
                  technological process, so you can pay maximum attention to your strategic and
                  business goals. Based on years of practice, we’ve come up with a robust and
                  well-established workflow, which proved to be highly effective and
                  client-oriented.
                </p>
                <h3>Transparent work process</h3>
                <p>
                  To ensure a project’s safety, we apply the latest security and agile-driven
                  practices, as well as provide transparent reports, according to a customer’s
                  request. Besides, you can come to our office any time to work with our team and
                  look at the whole working process yourself. Moreover, we can strengthen a project
                  by applying extra resources once you request to do so.
                </p>
              </article>
              <div className="tabs__column_last">
                <ul>
                  <li>
                    <h4>Timeframe</h4>
                    <p>Depends on project</p>
                  </li>
                  <li>
                    <h4>Deliverables</h4>
                    <p>
                      Environment setup <br />
                      Staging and production servers <br />
                      Intermediate releases <br />
                      Weekly Reports
                    </p>
                  </li>
                  <li>
                    <h4>Who involved</h4>
                    <p>
                      Business Analyst <br />
                      Project Manager <br />
                      Stakeholders <br />
                      Development team <br />
                      QA team <br />
                      Devops
                    </p>
                  </li>
                  <li>
                    <h4>Technology</h4>
                    <TechnologyGroup technology={['react', 'reactNative', 'nodejs', 'gatsby']} />
                  </li>
                </ul>
              </div>
            </li>
            <li className="tabs__item">
              <article className="tabs__column_first">
                <p>
                  We believe it’s better to double-check everything from scratch, so we go through
                  the pre-launch checklist to ensure the project is fully-functioning and ready for
                  deployment.
                </p>
                <p>
                  Then we can either upload your solution to the production server in case it’s a
                  web-driven app or submit it to online stores such as the AppStore or Google Play
                  in case it’s a mobile-based app.
                </p>
              </article>
              <div className="tabs__column_last">
                <ul>
                  <li>
                    <h4>Timeframe</h4>
                    <p>About a week</p>
                  </li>
                  <li>
                    <h4>Deliverables</h4>
                    <p>Final release</p>
                  </li>
                  <li>
                    <h4>Who involved</h4>
                    <p>
                      Business Analyst <br />
                      Designer <br />
                      Project Manager <br />
                      Devops
                    </p>
                  </li>
                  <li>
                    <h4>Technology</h4>
                    <Technology list={['amazon']} />
                  </li>
                </ul>
              </div>
            </li>
            <li className="tabs__item">
              <article className="tabs__column_first">
                <p>
                  Due to the client-oriented approach, our company keeps an eye on your product
                  after the final release and helps you scale it once the total number of end-users
                  starts growing.
                </p>
                <p>
                  At Brocoders, we care deeply about our customers and the solutions we develop, so
                  we provide continuous maintenance and support to our clients, as well as assign a
                  dedicated developer to a project to fix any possible bugs and respond to
                  inquiries.
                </p>
              </article>
              <div className="tabs__column_last">
                <ul>
                  <li>
                    <h4>Timeframe</h4>
                    <p>About a week</p>
                  </li>
                  <li>
                    <h4>Deliverables</h4>
                    <p>Fixes and improvements</p>
                  </li>
                  <li>
                    <h4>Who involved</h4>
                    <p>
                      Account manager <br />
                      Project Manager <br />
                      Stakeholders <br />
                      Development team
                    </p>
                  </li>
                </ul>
              </div>
            </li>
          </Tabs>
        </div>
      </section>
      <section className={styles.section}>
        <ReviewSlider swiperClassName={styles.blockquoteSwiper}>
          <Blockquote
            text="“With Brocoders, we were able to set up a highly skilled IT team in the shortest amount of time. For finding a team with similar skills we would have needed 4-6 months instead of the instant start.”"
            img={data.davidNeuendorf.childImageSharp.fluid}
            logoImg={data.heyPractice.publicURL}
            isLogo={true}
            logoStyles={styles.HeyPracticeLogo}
            isRating
            withTitle
            author={
              <React.Fragment>
                <strong>David Neuendorf </strong> <br />
                CEO,{' '}
                <a
                  href="https://www.heypractice.com/de/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.BqLink}
                >
                  HeyPractice.com
                </a>
              </React.Fragment>
            }
          />
          <Blockquote
            img={data.kalev.childImageSharp.fluid}
            isRating={true}
            logoImg={data.adact.publicURL}
            isLogo={true}
            withTitle
            text='"We’ve tested the product and have acquired over two million interactions without experiencing downtime or bug-related issues, so I can confidently say that Brocorders has met our success criteria."'
            author={
              <React.Fragment>
                <b>Kalev Kärpuk</b>
                <br />
                CEO &amp; Founder,{' '}
                <a href="https://adact.me/" target="_blank" className="link">
                  Adact
                </a>
              </React.Fragment>
            }
          />
          <Blockquote
            text={`“I was extremely satisfied and rate them 100%.\n\nCommunication was clear and continuous. We were always informed about progress and issues that inevitably poop up during a complex project.”`}
            img={data.patrickKedziora.childImageSharp.fluid}
            logoImg={data.kedzoh.publicURL}
            isLogo={true}
            blockquoteClassName={styles.blockquote}
            isRating
            withTitle
            author={
              <React.Fragment>
                <strong>Patrick Kedziora </strong> <br />
                CEO,{' '}
                <a
                  href="https://www.f6s.com/company/kedzoh#about"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.BqLink}
                >
                  Kedzoh
                </a>
              </React.Fragment>
            }
          />
        </ReviewSlider>
      </section>
      <section className={clsx('section', styles.recognitionBox)}>
        <div className="inner">
          <h2 className={clsx(styles.sectionTitle, styles.marginsRecognition)}>Recognition</h2>
          <Recognition />
        </div>
      </section>
      <section className="inner">
        <FAQ data={faqData} />
      </section>
      <Booking />
    </Layout>
  );
}

export default Main;
